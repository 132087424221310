/* eslint-disable */
import {API, setToken} from '../api'
import {acaoApi} from '../helpers/enums'
import {EventBus} from '../helpers/event-bus'
/************************OLD***********************/
// export const lista = (url) => {
//   return API.get(url)
// }
export const lista2 = (url, objeto) => {
  return API.get(url, {params:objeto})
}

// export const grava = (url, objeto) => {
//   return API.post(url, objeto)
// }

export const remove = (url, objeto) => {
  return API.delete(url + '/' + objeto)
}
//
// export const pega = (url, id) => {
//   return API.get(url + '/' + id)
// }
//
// export const listaByGrupo = (url, objeto) => {
//   return API.get(url, {params:objeto})
// }
/***********************************************/
export const lista = (url) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('usuario_id'))
  let res = API.get(url)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
  // return API.get(url)
}

// //
export const processar = (url, objeto, acao) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('usuario_id'))
  if (acao === acaoApi.REMOVER) {
    var res = API.delete(url, {data: objeto})
  } else {
    var res = API.post(url, objeto)
  }

  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}


export const pegaLista = (url, data) => {
  let res = API.get(url, {params: {data: data}})
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}
export const pegaDados = (url, data) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('usuario_id'))
  let res = API.post(url, data)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
  // return API.post(url, data)
}


export const autentica = (url, objeto) => {
  let res = API.post(url, objeto)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}


const isTokenExpired = (response) => {
  if(response.status === 401){
    let renovar = (response.data.message === "Token has expired")
    EventBus.$emit('RENOVAR_LOGIN', renovar)
  }
}

export const setarToken = (token, userID) => {
  setToken(token, userID)
}
