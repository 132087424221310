/* eslint-disable */
const state = {
  drawer: true,
  modal: false,
  sidebarId: 'principal',
  sidebarColor: 'rgba(0, 0, 0, 0.8)',
  modalfiltro: false,
  search: '',
  toolbarDash: null,
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  btnDownload: false,
  tab: '',
  msgSuccess: '',
  background: '',
  checkboxImg: 1,
  chktema: true,
  showFilter: false,
  swatches: '#000000'
}

const getters = {
  getSearch: state => {
    return state.search
  },
  pegarShowFilter: state => {
    return state.showFilter
  },
}

const actions = {
  toggledrawer ({ commit }) {
    commit('SET_DRAWER', !state.drawer)
  },
  setShowFilter({commit}, valor) {
    commit('SET_SHOWFILTER', valor)
  },
  setModal ({ commit }, valor) {
    commit('SET_MODAL', valor)
  },
  setSidebarID ({ commit }, valor) {
    commit('SET_SIDEBARID', valor)
  },
  setModalFiltro ({ commit }, valor) {
    commit('SET_MODALFILTRO', valor)
  },
  toggleModalFiltro ({ commit }) {
    commit('SET_MODALFILTRO', !state.modalfiltro)
  },
    setSidebarColor ({ commit }, valor) {
    commit('SET_SIDEBARCOLOR', valor)
  },
  setSearch ({ commit }, valor) {
    commit('SET_SEARCH', valor)
  },

  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor)
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor)
    commit('SET_SNACKSUCCESS', true)
  },
  setTab ({ commit }, valor) {
    commit('SET_TAB', valor)
  },
}

const mutations = {
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_SHOWFILTER(state, payload) {
    state.showFilter = payload
  },
  SET_MODAL (state, payload) {
    state.modal = payload
  },
  SET_SIDEBARID (state, payload) {
    state.sidebarId = payload
  },
  SET_MODALFILTRO (state, payload) {
    state.modalfiltro = payload
  },
  SET_SIDEBARCOLOR (state, payload) {
    state.sidebarColor = payload
  },
  SET_SEARCH (state, payload) {
    state.search = payload
  },
  SET_TOOLBARDASH (state, payload) {
    state.toolbarDash = payload
  },
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },

  SET_TAB (state, payload) {
    state.tab = payload
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
