/* eslint-disable */
import axios from 'axios'

export const API = axios.create({
  withCredentials: false,
  headers: {
    // 'content-type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    UserId: sessionStorage.getItem('usuario_id'),
    RotinaId: ''
  },
  baseURL: process.env.VUE_APP_API_URL,
  BASE_URL: '/',
  VUE_TITLE: process.env.VUE_TITLE
})

export function setToken(token, userID) {
  // sessionStorage.setItem('token', token)
  // API.defaults.headers.Authorization = `Bearer ${token}`
  // API.defaults.headers.UserId = userID
}

