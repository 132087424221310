<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div>
						<v-avatar v-if="is_pdf()" size="35" class="text-left">
									<v-icon size="30" >mdi-file-pdf-box</v-icon>
						</v-avatar>

						<v-menu v-else open-on-hover location="end" transition="scale-transition">
									<template v-slot:activator="{ props  }">
												<v-avatar v-bind:="props " size="35" class="text-left">
															<v-img  :src="getImagem(item, '50')" alt="avatar"></v-img>
												</v-avatar>
									</template>
									<v-card style="width: 300px" class="menu_card">
												<v-card-title>
															<v-img :src="getImagem(item, '400')" alt="avatar" class="imgZoom" contain></v-img>

												</v-card-title>
									</v-card>
						</v-menu>
			</div>
</template>

<script>
   /* eslint-disable */
   import empty from '../assets/img/empty.jpg'

   export default {
      name: "MenuImagem",
      props: ['item'],
      data: () => ({
         left: 0,
         top: 0
      }),

      methods: {
         is_pdf() {
            var extensao = ''
            if ( this.item.urls_imagem.length >0){
               var anexo = this.item.urls_imagem[0].urls.find(list => list.tamanho === 'original')
               extensao = anexo.url.split('.').pop()
												}
            return extensao === 'pdf'
         },
         selecionaImagem(urls, tamanho, anexoId) {
            // console.log(urls);
            let url
            let urlsPesquisa
            if (anexoId !== undefined) {
               let tempAnexo = urls.find(x => x.anexo === anexoId)
               if (tempAnexo !== null && tempAnexo !== undefined) {
                  urlsPesquisa = tempAnexo.urls
               }
            } else {
               if (urls !== undefined && urls.length > 0) {
                  let achou = urls.find(c => c.capa === 1)
                  if (achou !== undefined) {
                     urlsPesquisa = achou.urls
                  } else {
                     urlsPesquisa = urls[0].urls
                  }
               }
            }
            if (urlsPesquisa !== undefined && urlsPesquisa.length > 0) {
               url = urlsPesquisa.find(x => x.tamanho === tamanho)

               if (url === undefined) {
                  url = urlsPesquisa.find(x => x.tamanho === 'original')
               }
            } else {
               url = {
                  url: empty
               }
            }
            return url.url
         },

         getImagem(item, tam = 400) {
            // console.log('getImagem:', item);
            var src = ''
            if (item.urls_imagem !== undefined) {
               src = this.selecionaImagem(item.urls_imagem, tam)
            } else {
               if (item.imagens.length > 0) {
                  src = item.imagens[0].src
               } else {
                  src = this.selecionaImagem(item.urls_imagem, tam)
               }
            }
            return src
         },
      },
      mounted() {
      }
   }
</script>
