<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div>
						<v-dialog style="z-index: 1000" v-model="Mostrar" :width="mobileModal(width)" persistent :fullscreen="fullscreen"
																:hide-overlay="overlay">
									<v-card>
												<v-card-title class="gradiente" primary-title

												>
															{{getTitulo()}}
												</v-card-title>
												<v-card-text>
															<div>
																		<slot></slot>
															</div>
												</v-card-text>
									</v-card>
						</v-dialog>
			</div>
</template>

<script>
   /* eslint-disable */
   import miscMixin from '../helpers/misc'
   import {mapGetters} from "vuex";

   export default {
      mixins: [miscMixin],
      props: {
         titulo: {
            default: ''
         },
         modal: {
            default: false
         },
         fullscreen: {
            default: false
         },
         dragable: {
            default: false
         },
         width: {
            default: '50%'
         }
      },
      name: "ProModalCadastro",

      data() {
         return {
            overlay: false,
            margem_left: '25',
            classe: '',
         }
      },
      methods: {
         mobileModal(size) {
            var width = window.screen.width
            if (width <= 400) {
               size = '100%'
            } else if (width >= 400 && width <= 600) {
               size = '90%'
            } else if (width >= 601 && width <= 900) {
               size = '80%'
            } else if (width >= 901 && width <= 1300) {
               size = '70%'
            }
            return size
         },
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         getTitulo() {
            if (this.validarCampo(this.titulo)) {
               return this.titulo
            } else {
               return this.$router.currentRoute.meta
            }
         },
      },
      computed: {
         Mostrar: {
            get() {
               return this.modal
            },
            set() {
               return this.Mostrar
            }
         },
      },
      watch: {}
   }
</script>
