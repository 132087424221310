<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
	 <div>
					<div id="header">
								<v-app-bar class="gradiente" flat style="" >
											<v-app-bar-nav-icon @click="toggledrawer" color="white" v-if="draweble"></v-app-bar-nav-icon>

											<div style="width: 50%; margin-left: 2px" v-show="showSearch" >
														<v-text-field  density="compact" name="search"   color="white"  v-model="model" :search-input.sync="pesquisa"
																													@keyup="Pesquisar" solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Pesquisar">
																	<template v-slot:append>
																				<v-btn v-if="showBtnFilter" class="bnt_primary" @click="toggleModalFiltro()" size="small">
																							<v-icon>mdi-filter</v-icon>
																				</v-btn>
																	</template>
														</v-text-field>
											</div>
											<v-spacer></v-spacer>
											<slot name="more_content"></slot>

											<div style="margin-right: 5%">
														<v-btn v-if="showBtnFilter" @click="toggleModalFiltro()" text>
																	<v-icon>fa fa-sliders</v-icon>
														</v-btn>
											</div>
											<slot name="p_toolbar"></slot>
								</v-app-bar>
					</div>
					<v-container fluid>
								<slot name="content"></slot>
					</v-container>
	 </div>
</template>

<script>
  /*eslint-disable*/
  import {mapActions, mapGetters, mapState} from "vuex";
  import miscMixin from '../helpers/misc'
  import TokenExpirationTimer from "./TokenExpirationTimer";

  export default {
    name: "PForm",
					components:{
        TokenExpirationTimer
					},
    mixins: [miscMixin],
    props: {
      showSearch: {
        type: Boolean,
        default: false
      },
      showBtnFilter: {
        type: Boolean,
        default: false
      },
      draweble: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        left: '0px',
        left2: '260px',
        model: null,
        hide: null,
        pesquisa: null,
      }
    },
    methods: {
       ...mapActions('token', ['cancelarToken', 'start', 'setRenovarLogin']),
      ...mapActions('geral', [
        'setShowFilter',
        'setModalFiltro',
        'toggleModalFiltro',
        'toggledrawer',
        'setSearch']),
      Pesquisar(event) {
         if (event.keyCode === 13) { // ENTER
            this.setSearch(this.model)
        }
      },
    },
    created() {
      this.model = this.validarCampo(this.search) ? this.search : ''
      this.setSearch(this.model)
    },
    computed: {
       ...mapState('token', {iniciado: 'iniciado'}),
      ...mapGetters('geral', {search: 'getSearch'}),

      ...mapState('geral', {
        drawer: 'drawer',
        modalFiltro: 'modalFiltro',
        showFilter: 'showFilter',
      }),
    },
    watch: {
      'model': function () {
        if (!this.validarCampo(this.model)) {
          this.setSearch("")
        }
      },
      pesquisa: function (val) {
        if (this.validarCampo(val)) {
          this.setSearch(val)
        }
      },
    },
    mounted() {
       var _this = this
       window.onscroll = function() {
          _this.hide = document.documentElement.scrollTop <= 50
       }
    }
  }
</script>
<style scoped>
	 #header{
			display:block;
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background:transparent;
			text-align:center;
			z-index: 100;
	 }

</style>
