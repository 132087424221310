/* eslint-disable */
import {lista, lista2, pegaDados} from '../../services/GenericAPI'

const url = '/grupo'

const state = {
   all: [],
   allSubgrupos: []
}

const getters = {
   listaGruposSemFiltro: (state) => {
      return state.all
   },
   listaSubGrupos: (state) => {
      return state.allSubgrupos
   },
}

const actions = {
   setAllSubGrupos({commit}, lista) {
      commit('SET_SUBGRUPOS', lista)
   },

   async setAllGrupos({commit}) {
      try {
         const list = (await lista(url)).data
         commit('SET_GRUPOS', list)
      } catch (error) {
         console.error(error);
      }
   },
   async setAllGruposOrSubgrupo({commit},{tabela,filtro, onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', filtro)).data

         commit(tabela === 1 ? 'SET_GRUPOS' : 'SET_SUBGRUPOS' , list)
         onSucesso()
      } catch (error) {
         console.error(error);
         onErro()
      }
   }
}

const mutations = {
   SET_GRUPOS(state, grupos) {
      state.all = grupos
   },
   SET_SUBGRUPOS(state, grupos) {
      state.allSubgrupos = grupos
   }
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
