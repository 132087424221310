<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
			<div>
						<v-tooltip text="Tempo para a sessão expirar"  location="bottom" aria-label="timer">
									<template v-slot:activator="{ props }">
												<span v-bind="props"> {{montaTexto()}} </span>
									</template>
						</v-tooltip>
						<span v-if="pegaTimeCount <= 30000" class="renovar" @click="renovarLogin()">
      <v-icon aria-label="refresh" >mdi-refresh</v-icon>
    </span>
						<span style="margin-right: 14px;"></span>
			</div>
</template>

<script>
   import {mapGetters, mapActions} from 'vuex'

   export default {
      name: 'TokenExpirationTimer',
      props: {},
      methods: {
         ...mapActions('token', ['setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
         montaTexto() {
            if (this.pegaTimeCount === 0) {
               return ''
            }
            let ss = Math.floor((this.pegaTimeCount / 1000) % 60)
            let mm = Math.floor((this.pegaTimeCount / (1000 * 60)) % 60)
            let hh = Math.floor((this.pegaTimeCount / (1000 * 60 * 60)) % 24)

            hh = (hh < 10) ? '0' + hh : hh
            mm = (mm < 10) ? '0' + mm : mm
            ss = (ss < 10) ? '0' + ss : ss
            return hh + ':' + mm + ':' + ss
         },

         renovarLogin() {
            if (this.pegaTimeCount > 0) {
               this.setShowRenovarLoginMsg(false)
               this.setReloadPageRenovarLogin(false)
            }
            this.setRenovarLogin(true)
         }
      },
      computed: {
         ...mapGetters('token', ['pegaTimeCount'])
      },
      mounted() {
      }
   }
</script>

<style scoped>
			span.renovar {
						margin-left: 6px;
			}

			span.renovar:hover {
						cursor: pointer;
			}
</style>
