/* eslint-disable */
import {Anexo} from '../models/anexo'
import empty from '../assets/img/empty.jpg'
import miscMixin from './misc'

export default {
  mixins: [miscMixin],
  data() {
    return {
      anexo: new Anexo(),
      file: null,
      showCropper: false,
      imagens: [],
      classe: 'vcropperBackground',
      IMG: null
    }
  },
  components: {
  },
  methods: {
    downloadFile(anexo, model) {
      // if ((anexo.url !== undefined && anexo.url !== null && anexo.url !== '') || (anexo.src !== undefined && anexo.src !== null && anexo.src !== '')) {
      //   let link = document.createElement('a')
      //   if (anexo.url !== undefined && anexo.url !== null && anexo.url !== '') {
      //     link.href = process.env.VUE_APP_API_URL + model + '/download-anexo/' + anexo.anexo_id
      //     link.setAttribute('target', '_blank')
      //   } else if (anexo.src !== undefined && anexo.src !== null && anexo.src !== '') {
      //     link.href = anexo.src
      //     link.download = (anexo.nome ? anexo.nome : anexo.anexo_id) + '.' + anexo.extensao
      //   }
      //   document.body.appendChild(link)
      //   link.click()
      //   document.body.removeChild(link)
      // } else {
      //   alert('Não há arquivo a ser baixado.')
      // }
    },
    // uploadFile(e){
    //   console.log('uploadFile');
    //   let self = this
    //   const file = e.target.files[0];
    //   let extensao = file.name.split('.').pop()
    //
    //   if (e !== null && e !== undefined) {
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       let anexo=new Anexo('', e.target.result, false,true)
    //       anexo.extensao = extensao
    //       self.imagens.push(anexo)
    //       // console.log(self.imagens);
    //       EventBus.$emit('SET_IMAGENS', self.imagens)
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },
    // carregarArquivo(event){
    //   console.log('carregarArquivo');
    //   let _this = this;
    //
    //   const file = event.target.files[0];
    //   if (!file) return;
    //
    //   this.files = event.target.files
    //   var quantImagens = this.files.length;
    //     // var fileSize = (Math.round(this.files[0].size * 100 / (1024 * 1024)) / 100).toString();
    //     // if (fileSize > 3) {
    //     //   alert('Tamanho da imagem excedido:' + fileSize + 'Mb \nO tamanho máximo permitido é de 3 Mb')
    //     // } else {
    //     //   _this.onFileChange(event)
    //     // }
    //
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     // this.src = e.target.result;
    //     // this.dialog = true
    //     // this.$nextTick(() => {
    //     //   this.$refs.cropper.cropper.replace(this.src);
    //     // });
    //   };
    //   reader.readAsDataURL(file);
    //
    // },
    selecionaImagem(urls, tamanho, anexoId) {
      // console.log(urls);
      let url
      let urlsPesquisa
      if (anexoId !== undefined) {
        let tempAnexo = urls.find(x => x.anexo === anexoId)
        if (tempAnexo !== null && tempAnexo !== undefined) {
          urlsPesquisa = tempAnexo.urls
        }
      } else {
        if (urls !== undefined && urls.length > 0) {
          let achou = urls.find(c => c.capa === 1)
          if (achou !== undefined) {
            urlsPesquisa = achou.urls
          } else {
            urlsPesquisa = urls[0].urls
          }
        }
      }
      if (urlsPesquisa !== undefined && urlsPesquisa.length > 0) {
        url = urlsPesquisa.find(x => x.tamanho === tamanho)

        if (url === undefined) {
          url = urlsPesquisa.find(x => x.tamanho === 'original')
        }
      } else {
        url = {
          url: empty
        }
      }
      return url.url
    },

    preencheObjeto(objeto, tamanho) {
      this.imagens = []
      var  _this = this
      if (objeto.urls_imagem !== undefined) {
        objeto.urls_imagem.forEach(u => {
          let anexo = new Anexo(this.selecionaImagem(objeto.urls_imagem, tamanho, u.anexo), '', u.capa, true)
          anexo.anexo_id = u.anexo
          // console.log(anexo);
          if (anexo.url !== empty) {
            _this.imagens.push(anexo)
          }
        })
      } else {
        if (objeto.imagens.length > 0) {
          // console.log('ELSE');
          objeto.imagens.forEach(u => {
            this.imagens.push(u)
          })
        }
      }
    },
    defineCapaImagem(id) {
      this.imagens.forEach(i => {
        i.capa = (i.anexo_id === id)
      })
    },
  }
}
