/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";


export const ProdutoSCH = (perPage)=> {
    var filtro = new Filtro()
    filtro.perPage = perPage
    filtro.fields = ['itens.codigo', 'itens.descricao','grupo','anexos.referencia_id']

   filtro.join.addLeft('anexos', 'anexos.referencia_id', 'itens.codigo')
   filtro.addOrder('anexos.referencia_id', 'desc')
   filtro.addOrder('itens.codigo', 'asc')
   filtro.filters.addFilter('bloqueio','N','=')
   return filtro
}


export const  getProdutoSCH = (campo, valor, condicao)=>{
  var filtro = new Filtro()
  filtro.perPage = 1
  filtro.filters.addFilter(campo,valor,condicao)
  return filtro
}

