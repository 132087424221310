<template>
			<v-app id="app">
						<v-main class="backmain" id="backmain">
									<router-view/>
						</v-main>
			</v-app>
</template>
<script>
   export default {
      name: 'App',
      mounted() {
         // var sessao = document.getElementById('backmain')
         // if (sessao !== null){
         //    var cor = process.env.VUE_APP_COR !== null ? process.env.VUE_APP_COR : 'rgba(0, 0, 0, 0.2)'
         //    // sessao.style = "background-image: linear-gradient(130deg, "+cor+" 50%, "+cor+" 100%), url('../assets/img/fundo.png')!important;"
         // }
      }
   }
</script>
<style scoped>
			.backmain {
						background-image: url('./assets/img/fundo.png') !important;
						background-repeat: no-repeat;
						background-size: cover !important;
			}
</style>
