/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'

import usuarios from './modules/usuarios'
import token from "./modules/token";
import produtos from "./modules/produtos";
import grupos from "./modules/grupos";

export default createStore({
  modules: {
    token,
    geral,
    grupos,
    usuarios,
    produtos,


  }
})
