import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import './assets/css/main.scss'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import 'aos/dist/aos.css'
import {sync} from 'vuex-router-sync'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'
import ModalCadastro from './components/ModalCadastro'
import 'lightbox2/dist/css/lightbox.css'
import PForm from "./components/PForm";
import VueTheMask from 'vue-the-mask'
import TableIterator from "./components/table/TableIterator";
import MenuImagem from "./components/MenuImagem";
import VCropper from "./components/VCropper/VCropper";
import VConfirm from "./components/VConfirm";
import lightbox from 'lightbox2'
const vuetify = createVuetify({
  components,
  directives,
})


const app = createApp(App)
sync(store, router)
app.use(router)
app.use(vuetify)
app.use(store)
app.use(VueTheMask)
app.use(lightbox)
app.component('table-iterator', TableIterator)

app.component('v-confirm', VConfirm)
app.component('pro-modal', ModalCadastro)
app.component('p-form', PForm)
app.component('menu-imagem', MenuImagem)
app.component('v-cropper', VCropper)
app.mount('#app')
