/* eslint-disable */
import {lista, processar, autentica, pegaLista, setarToken, pegaDados} from '../../services/GenericAPI'
import {Filtro, Join, Left, Where} from "../../models/filtro/filtro";
import {UsuarioSCH} from "../../search/UsuarioSCH"

import {EventBus} from '../../helpers/event-bus'
import _ from 'lodash'
import {Usuario} from "../../models/usuario";

const url = '/usuario'

const state = {
  all: [],
  filtro: new Filtro(),
  usuario: new Usuario(),
}

const getters = {
  listaUsuarios: (state) => {
    return state.all
  },
  listaUsuariosSemFiltro: (state) => {
    return state.all
  },
  pegaUsuarioId: (state) => (id) => {
    let mun = state.all.find(usuario => usuario.usuario_id === id)
    return mun !== null ? mun.descricao : '** NÃO ENCONTRADO'
  },

  pegaUsuario: (state) => {
    return state.usuario
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['nome', 'email'],
       '%'+search+'%',
        'ilike'
      )
  },
  async getItem({commit}, id) {

    //  var filtro = getBaseSCH('participante_id', id, '=')
    //  const list = (await pegaLista(url, filtro)).data
    //   commit('SET_USUARIO', list.data[0])
    //  EventBus.$emit('GET_CONCLUIDO', true)

  },
  setUsuario({commit}, usuario) {
    commit('SET_USUARIO', usuario)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', UsuarioSCH(perPage))
  },
  async setAllUsuarios({commit}, {onSucesso, onErro}) {
    try {
      // console.log('setAllUsuarios');
      const list = (await pegaDados(url+'/get', state.filtro)).data
      // console.log('SET_USUARIOS:', list)
      commit('SET_USUARIOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarUsuario({commit}, {usuario, acao, onSucesso, onErro}) {
    try {
      const res = await processar(url + (acao === 'gravar' ? '/commit' : ''), usuario, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso();
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
  async autenticaUsuarios({commit}, {usuario, onSucesso, onErro}) {
    try {
       const res = (await autentica('login/auth', usuario))
      sessionStorage.setItem('usuario', res.data.usuario.nome)
      sessionStorage.setItem('usuario_id', res.data.usuario.usuario_id)
      setarToken(res.data.token, res.data.usuario.usuario_id)
      sessionStorage.setItem('token', res.data.token)
      sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000)

      this.dispatch('token/cancelarToken')
      this.dispatch('token/start', null)
      onSucesso();
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  },
}

const mutations = {
  SET_USUARIO(state, usuario) {
    state.usuario = usuario
  },
  SET_USUARIOS(state, usuarios) {
    state.all = usuarios
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
