import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   // base: process.env.BASE_URL,
   routes: [
      {path: '/', name: 'Login', meta:{title: 'Login'}, component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         meta:{title: 'Dash'},
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [
            {path: '/home', name: 'Home',meta:{title: 'Home'}, component: () => import(/* webpackChunkName: "Home" */ '../views/home/Home.vue')},
            {
               path: '/produtos',
               name: 'Produtos',
               meta:{title: 'Produtos'},
               component: () => import(/* webpackChunkName: "Produto" */ '../views/Cadastro/produtos/ProdutosLista.vue')
            },
            {
               path: '/usuarios',
               name: 'Usuarios',
               meta:{title: 'Usuarios'},
               component: () => import(/* webpackChunkName: "Produto" */ '../views/Cadastro/usuarios/Usuario.vue')
            },
         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         meta:{title: 'Not Found'},
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue')
      },

   ]
})
router.beforeEach((to, from) => {
   document.title = (to.meta?.title + ' - ' + process.env.VUE_APP_TITLE)  ?? process.env.VUE_APP_TITLE
})
export default router
