/* eslint-disable */
import {pegaDados, processar, remove} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {ProdutoSCH} from "../../search/ProdutoSCH";

var url = '/produto'

const state = {
   all: [],
   allItens: [],
   filtro: new Filtro(),
}

const getters = {
   pegaFiltro: (state) => {
      return state.filtro
   },

   allImagens: state => {
       return state.all
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.addSearch(['descricao', 'codigo'],
       "%" + search + "%",
       'ilike'
      )
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', ProdutoSCH(perPage))
   },
   async filtarProdutos({commit}, {filtro, onSucesso, onErro}) {
      try {
         const list2 = (await pegaDados(url + '/get', filtro))
         commit('SET_IMAGENS', list2.data)
         onSucesso()
      } catch (error) {
         onErro()
      }
   },

   async processarProduto({commit}, {produto, onSucesso, onErro}) {
      try {
         let res = await processar(url + '/commit', produto, 'gravar')
         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         console.log(error);
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },

   async removeImagem({commit}, codigo) {
      await remove('/produtoimagens', codigo)
   }
}

const mutations = {
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_IMAGENS(state, Imagens) {
      state.all = Imagens !== null && Imagens !== undefined ? Imagens : []
   }
}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
